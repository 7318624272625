import React, { useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'ouib... Remove this comment to see the full error message
import exitIntentDetector from 'ouibounce';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import withCookies from 'HOC/withCookies';

import Conditional from 'Components/common/conditional';
import { SwiperRefActions } from 'Components/common/customSwiper/interface';
import FeedSectionHeader from 'Components/common/feedSectionHeader';
import LazyComponent from 'Components/common/lazyComponent';
import ProductCardList from 'Components/common/productCardList';
import BannerCarousel from 'Components/desktop/bannerCarousel';
import NavigationButtons from 'Components/desktop/navigationButtons';
import SectionsTabCarousel from 'Components/desktop/sectionsTabCarousel';
import { SectionTabInfo } from 'Components/desktop/sectionsTabCarousel/interface';
import ViewAllLink from 'Components/desktop/viewAllLink';

import useDidUpdateEffect from 'Hooks/useDidUpdateEffect';
import { useSwiperArrows } from 'Hooks/useSwiperArrows';
import {
	getExitIntentConfiguration,
	getTopProductIds,
	trackEvent,
	trackPageView,
	trackSectionViews,
} from 'Utils/analytics';
import { getGlobalPageUrlAsPath, getSectionTabInfos } from 'Utils/cityUtils';
import { createSanitisedIdentifer, isBlogSupportedLang } from 'Utils/gen';
import HelmetUtilities from 'Utils/helmetUtils';
import {
	getAvailableProductIds,
	getBlogsByCityCode,
	getCategoryFeedSections,
	getCitiesMap,
	getCurrentCountryCode,
	getCurrentLanguageCode,
	getCurrentPage,
	getHost,
	getNearByCityCodes,
	getPersonas,
} from 'Utils/stateUtils';
import { capitalizeOnlyFirstLetter } from 'Utils/stringUtils';
import {
	getApiString,
	getCategoryParams,
	getLocationObjectFromRouter,
	getWebPathString,
} from 'Utils/urlUtils';

import { setLazyOverride } from 'Thunks/app';
import { fetchCategoryFeed } from 'Thunks/categories';
import { fetchProductList } from 'Thunks/productList';
import { changeCity } from 'Actions/city';
import { changePage } from 'Actions/page';
import { updateUserAttributes } from 'Actions/user';

import {
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
	CITY_PAGE,
	HOME_PAGE,
} from 'Constants/analytics';
import { PAGE_TYPE, SORT_TYPE } from 'Constants/constants';
import { strings } from 'Constants/strings';

const BlogsSection = dynamic(() => import('Components/common/blogsSection'));
const LongFormContent = dynamic(
	() => import('Components/common/longFormContent'),
);

const BreadCrumb = dynamic(() => import('Components/common/breadCrumb'));
const ReviewSection = dynamic(() => import('Components/common/reviewSection'));
const ThingsToDoSection = dynamic(
	() => import('Components/common/thingsToDoSection'),
);
const NearByCitiesCarousel = dynamic(
	() => import('Components/desktop/nearByCitiesCarousel'),
);
const MustDoThingsSection = dynamic(
	() => import('Containers/common/mustDoThingsSection'),
);
const DownloadAppSection = dynamic(
	() => import('Components/desktop/downloadAppSection'),
);
const ExpandedCategoryView = dynamic(
	() => import('Components/desktop/expandedCategoryView'),
);
const TopAttractionSection = dynamic(
	() => import('Components/desktop/topAttractionSection'),
);

const PersonaAffinityEntryBox = dynamic(
	() =>
		import(
			/* webpackChunkName: 'PersonaAffinityEntryBox' */ 'Containers/common/personaAffinity/entryBox'
		),
);

const CityPageContainer = styled.div`
	.top-experiences-container {
		margin-bottom: 1.875rem;
	}

	.top-attracions-section-wrapper {
		margin-top: 0;
	}

	.long-form-wrapper {
		padding-top: 0;
	}
`;

export const SectionHeaderRightContainer = styled.div`
	display: flex;
	align-items: center;
`;

const RecentlyViewedProductList = dynamic(
	() => import('Components/desktop/recentlyViewedListWrapper'),
	{
		ssr: false,
	},
);

/* Styled Components */
const SpaceBlock = styled.div<{ space: number }>`
	height: ${({ space }) => space}rem;
`;

const BreadCrumbContainer = styled.div`
	width: 100%;
	max-width: 75rem;
	margin: 5.25rem auto 2rem;
`;

const SectionContainer = styled.div`
	width: 100%;
	max-width: 75rem;
	margin: 0 auto;
`;

const sendAnalyticsEvents = (availableRecommendedProductIds: any) => {
	const top10Products = getTopProductIds(availableRecommendedProductIds);
	trackPageView({
		pageType: PAGE_TYPE.CITY,
		...(Array.isArray(top10Products) &&
			top10Products.length && { 'Top 10 Products': top10Products }),
	});
	// @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
	exitIntentDetector(false, getExitIntentConfiguration({}));
};

const CitiesPageElements = ({
	recommendedProductIds,
	sendUserAttributes,
	availableRecommendedProductIds,
	cookies,
	cityDisplayName,
	onCityChange,
	cityCode,
	location,
	lang,
	helmetFunctions,
	prismicLongFormContent,
	sections,
	onMountDispatch,
	pageType,
	blogPosts,
	shouldShowPersonaSection,
	onOverrideLazyload,
}: any) => {
	const recommendedProductsCarouselRef = useRef<SwiperRefActions>(null);
	const recommendedSwiperProps = useSwiperArrows();
	const language = useSelector(state => getCurrentLanguageCode(state));

	useEffect(() => {
		onMountDispatch({ cityCode, cookies, lang: language });
	}, []);

	useEffect(() => {
		sendAnalyticsEvents(availableRecommendedProductIds);
		sendUserAttributes({ lastCityViewed: cityDisplayName });
	}, [recommendedProductIds, cityDisplayName]);

	useDidUpdateEffect(() => {
		onCityChange({
			cityCode,
		});
	}, [cityCode]);

	const { slices, lfcSuffixOverride, wrapInFAQSchema } =
		prismicLongFormContent || {};

	const linkTexts = [
		strings.BREADCRUMB_HOME,
		strings.formatString(strings.THINGS_TO_DO_IN_CITY, cityDisplayName),
	];
	const linkUrls = [getGlobalPageUrlAsPath({ lang }), ''];

	const sectionTabInfos = getSectionTabInfos(sections);

	const toursHref = `${lang && lang !== 'en' ? '/[lang]' : ''}/tours/[city]`;

	const toursLink = `${
		lang && lang !== 'en' ? `/${lang}` : ''
	}/tours/${getWebPathString(cityCode)}`;

	const topExperiencesHeading = strings.formatString(
		strings.CTP_CFP_TOP_EXP_IN_CITY.title,
		cityDisplayName,
	);

	const handleTopExperienceCarouselPrev = () => {
		recommendedProductsCarouselRef.current?.prevSlide();
		trackSwiperArrowClick('backward', topExperiencesHeading);
	};

	const handleTopExperienceCarouselNext = () => {
		recommendedProductsCarouselRef.current?.nextSlide();
		trackSwiperArrowClick('forward', topExperiencesHeading);
	};

	const trackSwiperArrowClick = (
		direction: 'forward' | 'backward',
		sectionTitle: string,
	) => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.CAROUSEL.CHEVRON_CLICKED,
			[ANALYTICS_PROPERTIES.DIRECTION]:
				capitalizeOnlyFirstLetter(direction),
			...(sectionTitle
				? { [ANALYTICS_PROPERTIES.SECTION]: sectionTitle }
				: {}),
		});
	};

	const trackCategoryTabClick = ({
		sectionTabInfo,
		index,
	}: {
		sectionTabInfo: SectionTabInfo;
		index: number;
	}) => {
		const { title } = sectionTabInfo;
		trackEvent({
			eventName: ANALYTICS_EVENTS.CATEGORY_TAB_CLICKED,
			[ANALYTICS_PROPERTIES.CATEGORY_NAME]: title,
			[ANALYTICS_PROPERTIES.RANKING]: index + 1,
		});
	};

	return (
		<CityPageContainer className='feed-wrapper'>
			<HelmetUtilities {...helmetFunctions} />
			<div className='feed-page-wrapper'>
				<BannerCarousel cityDisplayName={cityDisplayName} />
				<div>
					<Conditional if={sectionTabInfos?.length < 4}>
						<SpaceBlock space={4} />
					</Conditional>

					<Conditional if={sectionTabInfos?.length >= 4}>
						<SectionsTabCarousel
							sectionTabInfos={sectionTabInfos}
							onOverrideLazyload={onOverrideLazyload}
							onSectionTabClick={trackCategoryTabClick}
							topOffset={206}
							resetAtTop
						/>
						<SpaceBlock space={3.2} />
					</Conditional>
					<RecentlyViewedProductList
						onScrollIntoView={trackSectionViews(
							ANALYTICS_EVENTS.CITY_PAGE.SECTION_VIEWED,
							HOME_PAGE.RECENTLY_VIEWED,
						)}
						title={strings.CTP_CFP_RECENTLY_CHECKED}
						lang={lang}
					/>
					{availableRecommendedProductIds?.length > 0 ? (
						<div className='core-column-container top-experiences-container'>
							<FeedSectionHeader
								headingType={'h2'}
								title={topExperiencesHeading}
								onScrollIntoView={trackSectionViews(
									ANALYTICS_EVENTS.CITY_PAGE.SECTION_VIEWED,
									CITY_PAGE.TOP_EXPERIENCES,
								)}
							>
								<Conditional
									if={
										availableRecommendedProductIds.length >
										4
									}
								>
									<SectionHeaderRightContainer>
										<ViewAllLink
											href={toursHref}
											to={toursLink}
										/>
										<NavigationButtons
											showLeftArrow={
												recommendedSwiperProps.showLeftArrow
											}
											showRightArrow={
												recommendedSwiperProps.showRightArrow
											}
											prevSlide={
												handleTopExperienceCarouselPrev
											}
											nextSlide={
												handleTopExperienceCarouselNext
											}
										/>
									</SectionHeaderRightContainer>
								</Conditional>
							</FeedSectionHeader>
							<ProductCardList
								productIds={availableRecommendedProductIds}
								showCityName={false}
								renderImagesInServer={true}
								productCarouselRef={
									recommendedProductsCarouselRef
								}
								onSlideChanged={
									recommendedSwiperProps.onSlideChanged
								}
								parentIdentifier={createSanitisedIdentifer(
									strings.CTP_CFP_TOP_EXP_IN_CITY.title,
								)}
							/>
						</div>
					) : null}
					<LazyComponent>
						<TopAttractionSection
							lang={lang}
							cityCode={cityCode}
							cityName={cityDisplayName}
							onScrollIntoView={trackSectionViews(
								ANALYTICS_EVENTS.CITY_PAGE.SECTION_VIEWED,
								CITY_PAGE.TOP_THINGS_TO_DO,
							)}
							attractionsCountLimit={15}
						/>
					</LazyComponent>
					<SpaceBlock space={1.8} />
					<Conditional if={shouldShowPersonaSection}>
						<LazyComponent>
							<PersonaAffinityEntryBox
								onScrollIntoView={trackSectionViews(
									ANALYTICS_EVENTS.CITY_PAGE.SECTION_VIEWED,
									CITY_PAGE.PERSONA_AFFINITY,
								)}
							/>
						</LazyComponent>
					</Conditional>
					<SpaceBlock space={5.4} />
					{sections.map((section: any, index: number) => (
						<LazyComponent
							key={section?.title}
							id={sectionTabInfos[index]?.lazyContainerId}
						>
							<div className='core-column-container'>
								<ExpandedCategoryView
									location={location}
									cityCode={cityCode}
									cityDisplayName={cityDisplayName}
									section={section}
									onScrollIntoView={trackSectionViews(
										ANALYTICS_EVENTS.CITY_PAGE
											.SECTION_VIEWED,
										section?.title,
									)}
									sectionIndex={index}
								/>
							</div>
						</LazyComponent>
					))}
				</div>
				<LazyComponent>
					<MustDoThingsSection
						cityName={cityDisplayName}
						cityCode={cityCode}
						lang={lang}
						onScrollIntoView={trackSectionViews(
							ANALYTICS_EVENTS.CITY_PAGE.SECTION_VIEWED,
							CITY_PAGE.MUST_DO_THINGS,
						)}
						cityCategoryRank={sectionTabInfos?.length + 1}
					/>
				</LazyComponent>

				<Conditional
					if={blogPosts.length >= 3 && isBlogSupportedLang(lang)}
				>
					<LazyComponent>
						<SectionContainer>
							<BlogsSection
								city={cityDisplayName}
								pageType={pageType}
								posts={blogPosts}
							/>
						</SectionContainer>
					</LazyComponent>
				</Conditional>
				<LazyComponent>
					<ThingsToDoSection
						cityDisplayName={cityDisplayName}
						cityCode={cityCode}
						lang={lang}
						onScrollIntoView={trackSectionViews(
							ANALYTICS_EVENTS.CITY_PAGE.SECTION_VIEWED,
							CITY_PAGE.BROWSE_BY_THEMES,
						)}
					/>
				</LazyComponent>
				<LazyComponent>
					<ReviewSection city={cityCode} isDesktop />
				</LazyComponent>
				<SpaceBlock space={6} />
				<div className='core-column-container'>
					<LazyComponent>
						<NearByCitiesCarousel />
					</LazyComponent>
				</div>

				<Conditional if={slices}>
					<LongFormContent
						slices={slices}
						lfcSuffixOverride={lfcSuffixOverride}
						entityDisplayName={cityDisplayName}
						wrapInFAQSchema={wrapInFAQSchema}
					/>
				</Conditional>
				<BreadCrumbContainer>
					<LazyComponent>
						<BreadCrumb linkTexts={linkTexts} linkUrls={linkUrls} />
					</LazyComponent>
				</BreadCrumbContainer>
				<LazyComponent>
					<DownloadAppSection isMid={true} />
				</LazyComponent>
			</div>
		</CityPageContainer>
	);
};

const mapStateToProps = (state: any, ownProps: any) => {
	const { query, pathname, asPath } = ownProps.router;
	const location = getLocationObjectFromRouter(query, pathname, asPath);
	const lang = getCurrentLanguageCode(state);
	const cityCode = getApiString(ownProps.router.query.city);
	const sections = getCategoryFeedSections(state, cityCode) || [];
	sections.forEach((section: any) => {
		section.title = section.title ?? '';
	});

	const cityDisplayName = state?.city?.citiesMap?.[cityCode]?.displayName;
	const recommendedProductIds =
		state?.productList?.[cityCode]?.[`sort-type=${SORT_TYPE.RECOMMENDED}`]
			?.productIdList || [];
	const blogPosts = getBlogsByCityCode({ state, cityCode: cityCode });
	const pageType = getCurrentPage(state);

	const shouldShowPersonaSection =
		(getPersonas(state, cityCode)?.personaAffinities ?? []).length > 2;

	return {
		location,
		lang,
		query,
		citiesMap: getCitiesMap(state),
		cityCode,
		cityDisplayName,
		recommendedProductIds,
		availableRecommendedProductIds: getAvailableProductIds(
			state,
			recommendedProductIds,
		),
		host: getHost(state),
		countryCode: getCurrentCountryCode(state),
		nearByCityCodes: getNearByCityCodes(state),
		sections,
		blogPosts,
		pageType,
		shouldShowPersonaSection,
	};
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
	// @ts-expect-error TS(7031): Binding element 'cityCode' implicitly has an 'any'... Remove this comment to see the full error message
	onMountDispatch({ cityCode }) {
		dispatch(changePage(PAGE_TYPE.CITY));
		dispatch(changeCity({ cityCode }));
		dispatch(
			fetchCategoryFeed({
				cityCode,
				lang: ownProps.router.query.lang || ownProps.lang,
			}),
		);
		dispatch(
			fetchProductList({
				cityCode,
				params: `sort-type=${SORT_TYPE.RECOMMENDED}`,
				lang: ownProps.router.query.lang || ownProps.lang,
			}),
		);
	},
	expandCategories(cityCode: any, categories: any) {
		categories.forEach((category: any) => {
			dispatch(
				fetchProductList({
					cityCode,
					params: getCategoryParams(category),
					lang: ownProps.router.query.lang || ownProps.lang,
				}),
			);
		});
	},
	// @ts-expect-error TS(7031): Binding element 'cityCode' implicitly has an 'any'... Remove this comment to see the full error message
	onCityChange({ cityCode }) {
		dispatch(changeCity({ cityCode }));
		dispatch(
			fetchCategoryFeed({
				cityCode,
				lang: ownProps.router.query.lang || ownProps.lang,
			}),
		);
		dispatch(
			fetchProductList({
				cityCode,
				params: `sort-type=${SORT_TYPE.RECOMMENDED}`,
				lang: ownProps.router.query.lang || ownProps.lang,
			}),
		);
	},
	sendUserAttributes(attributes: any) {
		dispatch(updateUserAttributes(attributes));
	},
	onOverrideLazyload() {
		return dispatch(setLazyOverride());
	},
});

const connectCitiesPage = connect(mapStateToProps, mapDispatchToProps);

const CitiesPage = withRouter(
	connectCitiesPage(withCookies(CitiesPageElements)),
);

export default CitiesPage;
